.blog-entry{
	border-radius: 3px;
  border: 1px solid #ebebeb;
  box-shadow: 0 1px 4px rgba(0,0,0,.2);
  margin-top:40px;
  margin-bottom: 40px;
  padding-left:0;
  padding-right:0;

  @media(min-width:$screen-md){
  	margin-left: 4.1666665%;
  	margin-right: 4.1666665%;
  }


  img{
  	height:200px;
  	border-radius: 3px;
  	width:100%;
  }

  h2{
  	padding:20px;
  	padding-top: 5px;
  	padding-bottom: 5px;
  
  }
  .htag{
		height: 125px;
  	text-overflow: ellipsis;
  	overflow: hidden;
  	margin-bottom: 20px;
  }
  p{
  	padding:20px;
  	padding-top: 5px;
  	
  }
  .ptag{

  	height: 80px;
  	text-overflow: ellipsis;
  	overflow: hidden;
  	margin-bottom: 20px;
  }
  .author{
  	padding-left:20px;
  	img{
  		width:32px;
  		height:32px;
  		border-radius: 32px;
  	}
  }

  .btn{
  	margin-bottom: 20px;
  	margin-right: 20px;
  }

}
