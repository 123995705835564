// add bootstrap variable settings here

@import 'bootstrap';
@import 'homepage';
@import 'products';
@import 'about';
@import 'blog';
@import 'header';
@import 'footer';
@import 'home';
@import 'hero';
@import 'midwestecosystem';
@import 'newsletter';
@import 'midwestcities';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto, sans-serif;

  * {
    box-sizing: border-box;
  }

  &.overflowlock {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

img {
  display: block;
  width: auto;
  max-width: 100%;
}

.content {
  padding: 40px 15px;
}

.navbar{
	a{
		color: $gray-dark;
	}
}

.top-button{
	padding-top:27px !important;
}

h5{
	letter-spacing: 0.4px;
}

h1{
	font-size: 35px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
}
h2{
	font-size:31px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
}
h3{
	font-size:25px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
}
p{
	font-size:16px;
	-webkit-font-smoothing: antialiased;
}

.bold {
    font-weight: 600;
}

.bebas {
  font-family: 'Bebas', sans-serif;
}

.orange-bg{
	background-color: $brand-primary;
}
.purple-bg{
	background-color: #9467BC;
}
.yellow-bg{
	background-color: #FFB62C;
}
.purple-color{
	color: #9467BC;
}
.yellow-color{
	color: #FFB62C;
}
.grey-bg{
	background-color: $gray-light;
}

.grey-font{
	color: #888;
}

.blue-bg{
	background-color: $brand-recruit;
}
.blue-font{
	color: $brand-recruit !important;
}

small {
font-size: 10px;
}

.navbar-top{
	margin-bottom:0;
	border:none;
}

.no-sides{
	padding-left: 0;
	margin-left: 0;
	padding-right: 0;
	margin-right: 0;
}
.no-left{
	padding-left: 0;
	margin-left: 0;

}
.no-right{
	padding-right: 0;
	margin-right: 0;
}

.icon-image{
	border: 1px solid $gray;
  border-radius: 100px;
  padding:14px;
  width:60px;

}

.icon-image-white{
	border: 1px solid #ffffff;
  border-radius: 100px;
  padding:14px;
  width:60px;

}

.sub-feature{
	font-size: 18px;
	font-weight: 600;
	padding-left:10px;
	display: inline;
}

.white-button{
	background-color: #ffffff;
}

.white-button:hover{
	background-color: #ffffff;
	color: $brand-primary;
}

.hover-blue:hover{
	color: $brand-info !important;
}

.icon-down{
	margin-top:20px;

}

.icon-content{
	h3{
		font-size:17px;
		font-weight:600;
	}
	p{
		font-size:15px;
	}
}

footer{
	padding-top:75px;
	padding-bottom:75px;

	h5{
		font-size: 18px;
	}
}

.line{
	background: rgba(0, 0, 0, 0.1);
	width:100%;
	height: 1px;
}

.feature-header{
	.line{
		width:200px
	}
	h1{
		margin-top:10px;
		margin-bottom: 5px;
	}
}

.trusted-by {
  display: inline-block;
  padding: 10px 20px;
  margin: 0 auto;
  background: white;
  position: absolute;
  left: 50%;
  margin-top: -10px;
  transform: translate(-50%, 50%);
  border-radius: 100px;
  font-weight: 600;
  box-shadow: 0px 1px 6px #dbdbdb;
  font-size:16px;
  color: $gray-dark;
  @media(max-width: $screen-sm){
		width:250px;
	}
}

.sitemap{
	a{
		color: $gray-dark;
		font-size: 16px;
	}
}

@media(max-width: $screen-sm){
	.center-xs{
		text-align: center;
	}
}

.panel-heading{
	background-color:#F8F8FB;
	cursor: hand;
	cursor: pointer;


}

.panel-body{
	font-size: 16px;
}

.panel-default {
		a{
		color: #54666c;
	}
	a:hover{
		color:#54666c;
		text-style:none;
	}
}

.home-header {
  text-align: center;
  padding-top: 140px;
  background: url(/images/topoOrangeBar.png);
  background-repeat: no-repeat;
}

.small-header {
  .navbar-collapse {
    clear: both;
  }
  padding: 30px 0;
  &:after {
    height: 76px;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    margin-top: 10px;
    background: url(/images/topoOrangeBar.png);
    background-repeat: no-repeat;
    border-radius: 5px;
  }
}

table.dataTable thead .sorting:after {
    content: "" !important;
}

table.dataTable thead .sorting_asc:after {
  content: "" !important;
}

table.dataTable thead .sorting_desc:after {
  content: "" !important;
}

.main-logo {
  width: 30%;
}

.footer-logo {
  font-size: 14px;
  height: 150px;
  padding-top: 50px;
  margin-left: 50px;
  float: right !important;
}

.footer-section {
  text-align: left;
  padding-top: 50px;
  float: left;
}
.footer-copyright-text {
  font-size: 20px;
}

@media (max-width: 600px) {
  .main-logo {
    width: 70%;
    margin: auto;
    text-align: center;
  }
  .footer-logo {
    font-size: 14px;
    width: 100%;
    height: auto;
    padding: 15px 25px;
    margin-left: 0;
    float: none;
  }
  .footer-section {
    padding-top: 0;
    text-align: center;
    float: none;
  }
  .footer-copyright-text {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
  }
  .mobile-center {
    text-align: center;
  }
}


.popupContainer{
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  display:none;
}

.pop-up{
  width: 40%;
  height: 300px;
  background-color: #f5f7f9;
  margin-top: 100px;
  position: absolute;
  left: 30%;
  right: 30%;
  padding: 20px;
}

.exit{
  position: absolute;
  right: 0;
  top: -55px;
  color: #f5f7f9;
  cursor: pointer;
  font-size: 50px;
   -webkit-transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -o-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
  font-family: 'Roboto', sans-serif;
}

.pop-up>h4{
  font-size: 70px;
  font-weight: bold;
}
.pop-up>p{
  font-size: 22px;
  padding-top: 10px;
}

.pop-me{
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: #d9182b;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

.small-logo {
  height: 100px;
}


/* basic positioning */
.legend { list-style: none; }
.legend li { float: left; margin-right: 20px;}
.legend span { 
    position:relative;
    border-radius: 50%;
    width: 15px;
    height: 15px; 
    float:left;
    margin-right: 5px;
    margin-top: 2px;
  }
  .legend_font{
  font-size: 15px;
  margin-top: -3px;
  }

/* your colors */
.legend .one {background-color: #5BC0EB; }
.legend .two { background-color: #ffc387; }
.legend .three { background-color: #cc99cc; }

.legend .one_2017 {background-color: #43AA8B; }
.legend .two_2017 { background-color: #5BC0EB; }
.legend .three_2017 { background-color: #662E9B; }


.legend_compare { list-style: none; }
.legend_compare li { float: left; margin-right: 15px; }
.legend_compare span { 
position:relative;
    border-radius: 50%;
    width: 15px;
    height: 15px; 
    float:left;
    margin-right: 5px;
    margin-top: 2px;
}
.legend_compare .increased{border-style: solid; border-color: #4ca64c;}
.legend_compare .decreased{border-style: solid; border-color: #b20000;}

.circle_green{
  color: #4ca64c;
      font-size: 15px;

}
.circle_red{
  color: #b20000;
      font-size: 15px;

}
.circle_yellow{
    color: #cccc00;
    font-size: 20px;

}

.p_line_height{
  line-height: 13px;
}
 .menu { text-align: center; }
 .menu ul { display:inline-table; }
 .menu li { display:inline; }

 .paginate_button {
   background: none;
 }

 .btn {
   min-height: 35px;
   color: #fff;
   display: inline-block;
   a {
     color: inherit;
   }
 }

 .application {
   animation: fadeIn 0.5s ease-in-out;
 }

 @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
 }

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: $brand-primary;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #fff;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

textarea.form-control {
  padding-top: 10px;
  padding-bottom: 10px;
}
