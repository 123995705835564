@import 'colors';
@import 'typography';


.cities {
  padding-top: 74px;

  .cities--explore {
    padding: 16px 32px 40px;

    h1 {
      font-family: PT Sans, sans-serif;
      text-align: center;
      font-size: 32px;
      line-height: 130%; 
      color: $heading-color;
      font-weight: bold;
      margin-bottom: 24px;
    }
    
    // .gray-overlay {
    //   opacity: 0.4;
    //   filter: alpha(opacity=40); /* msie */
    //   background-color: $brand-dark-gray;
    // }

    .card-footer{
      font-size: 24px;
    }

    .card {
      margin-bottom: 12px;
    }

    .card-img:hover {
      opacity: 0.7;
      filter: alpha(opacity=40); /* msie */
      background-color: $brand-dark-gray;
    }

    p{
      font-size: 28px;
      color: white;
      background-color: $brand-blue;
      font-weight: bold;
      padding: 0px 8px;
    }
  }
  .cities--dive{
    padding: 0px 0px 64px;
    .container{
      h2{
        color: $brand-orange;
        font-weight: bold;
        padding-bottom: 4px;
        margin-bottom: 0;
      }

      p{
        line-height: 1;
      }
    }
  }

  @media (min-width: 800px) {
    padding-top: 80px;

    .cities--explore {
      padding: 64px 96px;

      h1 {
        font-size: 40px;
      }
    }
  }
}
