.herosection {
  background-image: url('/images/banner-background.png');
  background-repeat: repeat;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px 16px 24px;
  font-family: 'PT Sans', sans-serif;

  .herosection--text {
    flex: 1 1 100%;
    color: #fff;

    span {
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      display: block;
      margin-bottom: 8px;
    }

    h1 {
      font-size: 36px;
      font-weight: bold;
      line-height: 125%;
      margin-bottom: 24px;
      margin-top: 0;
    }

    h2 {
      font-size: 20px;
      line-height: 160%;
      margin-bottom: 24px;
    }
  }

  .herosection--image {
    flex: 1 1 100%;
  }

  @media (min-width: 800px) {
    padding: 40px 96px;
    flex-wrap: nowrap;

    .herosection--text {
      flex: 1 1 50%;
      margin-right: 24px;

      span {
        font-size: 26px;
        line-height: 30px;
      }

      h1 {
        font-size: 48px;
      }

      h2 {
        font-size: 24px;
        margin-bottom: 0;
      }
    }

    .herosection--image {
      max-width: 50%;
      flex: 1 1 50%;
    }
  }
}
