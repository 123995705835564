.header{

	color:white;
	text-align:center;	

	h1{
		margin: 20px 20px;
		margin-top:40px;
	}
	h3{
		max-width: 728px;
		
		text-align:center;	
		margin:0 auto;
		margin-bottom: 40px;

	}
}

.values{
	padding-top: 75px;
	padding-bottom: 75px;

	h2{
		padding-bottom:25px;
	}

	.value{
		padding-top:20px;
	}
}

.team{
	padding-top: 75px;
	padding-bottom: 75px;
	text-align: center;
	.name{
		font-weight:400px !important;
		font-size: 16px;
		margin-bottom: 0px;
		margin-top: 15px;
	}
	.role{
		font-size: 13px;
		margin-top: 0;
		margin-bottom: 20px;
		color: #a6abb2;
	}
	img{

	}
	.partner-logo{

	}
}
