@import 'colors';
@import 'typography';

.siteheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 74px;
  font-family: $base-font-family;
  padding: 0 16px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2;
  background-color: #fff;

  .siteheader--homeLink {

  }

  .siteheader--logo {
    max-width: 265px;
  }

  .siteheader--nav {
    display: none;
    position: fixed;
    top: 74px;
    left: 0;
    height: calc(100vh - 74px);
    width: 100vw;
    background-color: #fff;
    z-index: 2;
  }

  .siteheader--nav-open {
    display: block;
  }

  .siteheader--nav-left {
    display: flex;
    align-items: center;
  }

  .siteheader--nav-right {
    display: flex;
    align-items: center;
  }

  .siteheader--navLink {
    color: $nav-link-color;
    font-weight: bold;
    font-size: 18px;
    padding: 0 24px;

    &:hover {
      text-decoration: none;
      color: #000;
    }
  }

  .siteheader--navButton {
    border-radius: 2px;
    background-color: $brand-orange;
    color: #fff;
    padding: 6px 20px;
    border: 2px solid $brand-orange;

    &:hover {
      color: #fff;
      border-color: #fff;
    }
  }

  .siteheader--flyoutButton {
    background-color: transparent;
    border: none;
    cursor:pointer;
    height: 18px;
    box-sizing: unset;

    rect {
      fill: rgb(33, 100, 146);
      transform: translate(0, 0) rotateZ(0);
      transition: all 0.6s ease-in-out;
      transform-origin: center;
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
  }

  .siteheader--flyoutButton-open {
    .siteheader--flyoutButton-top {
      transform: translate(0, 6px) rotateZ(45deg);
    }
    .siteheader--flyoutButton-middle {
      opacity: 0;
    }
    .siteheader--flyoutButton-bottom {
      transform: translate(0, -8px) rotateZ(-45deg);
    }
  }

  @media (min-width: 800px) {
    height: 80px;
    padding: 0 96px;

    .siteheader--homeLink {
      margin-right: 48px;
    }

    .siteheader--nav {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      background-color: transparent;
      height: auto;
      width: auto;
      top: auto;
    }

    .siteheader--flyoutButton {
      display: none;
    }
  }
}
