@import 'colors';
@import 'typography';

.sitefooter {
  padding: 16px 64px 64px;
  background-color: $brand-dark-gray;
  text-align: center;
  font-size: 14px;

  .sitefooter--attribution {
    margin-bottom: 48px;

    span {
      display: block;
      margin-bottom: 8px;
      color: #fff;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    img {
      width: 75px;
      margin: 0 auto;
    }
  }

  .sitefooter--links {
    color: $footer-text-color;

    span {
      line-height: 160%;
      display: block;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }
    
  }

  .sitefooter--logos{
    ul{
      align-items: center;
    }

    a{
      width: 25px;
      display: inline-block;
      text-decoration: none;
    }
  }


  @media (min-width: 800px) {
    padding: 24px 96px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    .sitefooter--attribution {
      text-align: left;
      margin-bottom: 0;
    }

    .sitefooter--links {
      text-align: right;
    }
  }
}
