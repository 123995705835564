@import 'colors';
@import 'typography';

.home {
  padding-top: 74px;
  color: #000;

  * {
    overflow-wrap: break-word;
  }

  .home--ctas {
    padding: 32px 16px 24px;

    h1 {
      font-family: 'PT Sans', sans-serif;
      text-align: center;
      font-size: 32px;
      line-height: 130%; 
      color: $heading-color;
      font-weight: bold;
      margin-bottom: 32px;
      margin-top: 0;
    }
  }

  .home--cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &:not(:last-of-type) {
      margin-bottom: 64px;
    }
  }

  .home--ctaText {
    flex: 1 1 100%;

    h2 {
      color: $brand-orange;
      font-size: 28px;
      line-height: 120%;
      margin-bottom: 16px;
    }

    p {
      font-size: 20px;
      line-height: 160%;
      margin-bottom: 16px;
    }
  }

  .home--ctaImage {
    flex: 1 1 100%;
    margin-bottom: 32px;
  }

  .home--ctaLink {
    color: $brand-blue;
    font-size: 18px;
    line-height: 120%;
    display: flex;
    align-items: center;

    i {
      margin-left: 8px;
      text-decoration: none;
      font-size: 16px;
    }
  }

  .home--companies {
    background-color: $brand-gray-light;
    padding: 8px 24px 20px;
    text-align: center;

    h1 {
      font-size: 16px;
      font-weight: bold;
      color: $heading-color;
      margin-bottom: 24px;
    }
  }

  .home--logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
  }

  .home--logo {
    width: 50%;
    flex: 0 0 50%;
    padding: 0 20px;

    &:not(:last-of-type) {
      margin-bottom: 32px;
    }
  }

  .home--logoImage {
    margin: auto;
  }

  .home--companiesLink {
    color: $brand-blue;
    font-size: 14px;
    line-height: 200%;
    margin-top: 24px;
    display: block;
  }

  @media (min-width: 800px) {
    padding-top: 80px;

    .home--ctas {
      padding: 64px 96px 96px;

      h1 {
        font-size: 40px;
        margin-bottom: 40px;
      }
    }

    .home--cta {
      flex-wrap: nowrap;

      &:nth-of-type(odd) {
        flex-direction: row-reverse;
      }

      &:not(:last-of-type) {
        margin-bottom: 96px;
      }
    }

    .home--ctaText {
      flex: 0 0 calc(50% - 25px);
      width: calc(50% - 25px);

      h2 {
        font-size: 38px;
        margin-bottom: 24px;
      }

      p {
        font-size: 24px;
        margin-bottom: 24px;
      }
    }

    .home--ctaImage {
      flex: 0 0 calc(50% - 25px);
      width: calc(50% - 25px);
      margin-bottom: 0;
    }

    .home--ctaLink {
      font-size: 20px;
    }

    .home--companies {
      padding: 32px;
    }

    .home--logos {
      flex-wrap: nowrap;
    }

    .home--logo {
      width: auto;
      max-width: 216px;
      flex: 0 0 auto;
      padding: 0 24px;
      
      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }
}
