@import 'colors';
@import 'typography';

.midwest {
  padding-top: 50px;

  .midwest--lineChart{
    padding: 0px;
  }

  .midwest--explore {
    padding: 16px 32px 40px;

    h1 {
      font-family: PT Sans, sans-serif;
      text-align: center;
      font-size: 32px;
      line-height: 130%; 
      color: $heading-color;
      font-weight: bold;
      margin-bottom: 24px;
    }
  }

  .midwest--legend {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .midwest--legendLabel {
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    width: 25%;
    flex-basis: 25%;
    margin: 0;
  }

  .midwest--legendItem {
    font-size: 14px;
    line-height: 16px;
    width: 25%;
    flex-basis: 25%;
    text-align: center;
    white-space: nowrap;
  }

  .midwest--legendColor {
    border-radius: 2px;
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 8px;
  }

  .midwest--legendColor-blue {
    background-color: #5BC0EB;
  }

  .midwest--legendColor-orange {
    background-color: #ffc387;
  }

  .midwest--legendColor-purple {
    background-color: #cc99cc;
  }

  .midwest--legendColor-maroon2 {
    background-color: #ffbaba;
  }

  .midwest--legendText {
    display: inline-block;
  }

  .midwest--legendTrend {
    width: 50%;
    flex-basis: 50%;
    padding: 0 10px;
    margin-top: 16px;
  }

  .midwest--legendCircle {
    width: 12px;
    height: 12px;
    background-color: #fff;
    margin-right: 8px;
    display: inline-block;
    border-radius: 50%;
  }

  .midwest--legendCircle-up {
    border: 2px solid #4ca64c;
  }

  .midwest--legendCircle-new {
    border: 2px solid black;
  }

  .midwest--legendCircle-down {
    border: 2px solid #b20000;
  }

  .midwest--map {
    position: relative;
    width: 100%;
    height: 60%;
    margin: 0 auto 16px;
  }

  .midwest--showAllButton {
    padding: 10px 24px;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    background-color: $brand-orange;
    outline: none;
    border: none;
    border-radius: 2px;
    font-weight: bold;
    margin-bottom: 36px;
    border: 2px solid $brand-orange;

    &:hover {
      border-color: #fff;
    }
  }

  .midwest--mapButton {
    padding: 10px 24px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    background-color: $brand-orange;
    outline: none;
    border: none;
    border-radius: 2px;
    font-weight: bold;
    margin-bottom: 12px;
    border: 2px solid $brand-orange;

    &:hover {
      border-color: #fff;
    }
  }

  .midwest--mapButtonInactive {
      padding: 10px 24px;
      font-size: 14px;
      line-height: 16px;
      color: $heading-color;
      background-color: $brand-gray-light;
      outline: $brand-orange;
      border: solid;
      border-radius: 2px;
      font-weight: bold;
      margin-bottom: 12px;
      border: 2px solid $brand-orange;

      &:hover {
        border-color: #fff;
      }
    }


  .midwest--hideAllButton {
    display: none;
  }

  .dataTables_wrapper {
    display: none;
    overflow-x: scroll;
    margin-top: 24px;
  }

  .dataTables_filter {
    text-align: right;

    input {
      margin-left: 16px;
    }
  }

  .midwest--previousYearLink {
    padding-top: 32px;
    font-size: 20px;
    line-height: 20px;
    color: $brand-blue;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;

    i {
      margin-left: 8px;
      text-decoration: none;
      font-size: 15px;
    }
  }

  .midwest--blogLink {
    font-size: 24px;
    line-height: 50px;
    color: $brand-blue;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;

    i {
      margin-left: 8px;
      text-decoration: none;
      font-size: 15px;
    }
  }

  .incognito-mode{
      background-color: $brand-orange;
      color: white;
  }


  .midwest--rankings {
    padding: 8px 16px 32px;
    background-color: $brand-gray-light;

    h1 {
      text-align: center;
      font-size: 32px;
      line-height: 130%; 
      color: $heading-color;
      font-weight: bold;
      margin-bottom: 24px;
    }

    h6 {
      text-align: center;
    }
    .midwest--blog{
      padding-top: 32px;
    }
    #read_more {
      font-size: 24px !important;
    }
    a{
     padding: 8px 32px 8px 32px;
     font-size: 16px;
    }
  }

  .midwest--category {
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }

  .midwest--categoryTitle {
    color: $brand-orange;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  .midwest--categorySubtitle {
    font-size: 18px;
    font-weight: bold;
    font-family: PT Sans, sans-serif;
    color: $brand-dark-gray;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .midwest--categoryText {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    color: #000;
  }

  .midwest--categorySource {
    font-size: 11px;
    font-style: italic;
    line-height: 18px;
    color: $brand-dark-gray;
  }

  .midwest--highlights {
    padding: 32px 16px 60px;

    h1 {
      font-size: 28px;
      line-height: 32px;
      color: $brand-orange;
      margin-bottom: 24px;
      text-align: center; 
    }

    p{
      font-size: 20px;
      text-align: center;

    }
  }

  .midwest--highlightsList {
    padding-left: 16px;
  }

  .midwest--highlight {
    font-size: 18px;
    color: $brand-dark-gray;
    line-height: 26px;
    margin-bottom: 16px;

    b {
      font-weight: bold;
      font-style: normal;
    }
  }


  @media (min-width: 800px) {
    padding-top: 80px;

    .midwest--explore {
      padding: 16px 96px;

      h1 {
        font-size: 40px;
        margin-bottom: 20px;
      }
    }
    .midwest--lineChart{
      padding: 64px 96px;
    }

    .midwest--legendLabel {
      width: auto;
      flex-basis: auto;
      margin-right: 24px;
    }

    .midwest--legendItem {
      width: auto;
      flex-basis: auto;
      margin-right: 24px;
    }

    .midwest--legendTrend {
      width: auto;
      flex-basis: auto;
      padding: 0;
      margin-top: 0;
    }

    .midwest--showAllButton {
      margin-right: 40px;
    }

    .midwest--categories {
      display: flex;
      flex-wrap: nowrap;
    }

    .midwest--category {
      margin: 0 40px;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    .midwest--rankings {
      padding: 16px 96px;
    }

    .midwest--highlights {
      padding: 16px 96px;

      h1 {
        font-size: 38px;
        line-height: 45px;
        text-align: center;
      }

      p{
        font-size: 20px;
        text-align: center;
      }
    }

    .midwest--highlightsList {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }

    .midwest--highlight {
      font-size: 20px;
      line-height: 24px;
      padding-right: 32px;
    }
  }
}
