.map {
  background-image: url('/images/worldmap.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 85%;

  height: 720px;
  @media(max-width: 768px) {
    height: 500px;
  }
}

.product-map {
  top: 30%;

  vertical-align: middle;

  text-align: center;
  @media(max-width: 768px) {
    top: 10%;
  }
  > button {
    padding: 0 16px;

    font-size: 14px;
  }
}

.main-image {
  padding-right: 10%;
}

.main-text {
  border-left: 2px solid #c1c1c1;

  padding: 0 20% 0 10%;

  text-align: left;

  font-size: 4em;
  line-height: 1.2em;
  @media (max-width: 800px) {
    font-size: 2em;
  }
}

.text-red {
  color: #e94d23;
}

.table {
  display: table;
  .table-cell {
    display: table-cell;

    vertical-align: middle;;
  }
  .table-cell-left {
    width: 39.420420%;
  }
  .table-cell-right {
    width: 59.696969%;
  }
}


.orange-dots {
  padding-top: 60px;

  text-align: center;
}

.big-boy-text {
  padding-top: 40px;

  text-align: center;

  color: #000000;

  font-size: 6em;
  @media (max-width: 600px) {
    font-size: 2em;
  }
}

.small-big-boy-text {
  padding-top: 40px;

  text-align: center;

  color: #000000;

  font-size: 3em;
  @media (max-width: 600px) {
    font-size: 2em;
  }
}


.form-field-container {
  padding-bottom: 30px;
}

.form-field {
  border-radius: 1px;

  //padding: 30px;
  height: 62px;
  padding: 0 20px;

  width: 100%;

  color: #000000;

  font-size: 20px;
  &:hover {
    border-color: #e94d23;
  }
  &:focus {
    border-color: #e94d23;

    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(233, 102, 102, .6);
  }
}

.company-logos > div > div{
   height: 200px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.company-logo {
   width: 80%;
  @media (max-width: 600px) {
    padding: 0;
    padding-top: 20px;
    padding-left: 10%;

    width: 89%;

    text-align: center;
  }
}

.and-many-more {
  margin-top: -230px;
  @media (max-width: 600px) {
    margin-top: 0;

    padding-top: 20px;
    padding-bottom: 0;
    padding-left: 20%;

    width: 80%;

    text-align: center;
  }
}

.circle {
  position: relative;
  margin-top: 5%;
  @media (max-width: 600px) {
    margin-top: 0;

    width: 98%;
  }
}

.circle-image {
  text-align: center;
  position: absolute;
  height: 100%;
  bottom: 10%;
  right: 25%;
  left: 25%;
  z-index: 0;
  svg {
    // height: 125%;
    @media (max-width: 600px) {
      height: auto;
      width: 98%;
    }
  }
}

.circle-title {
  position:relative;
  margin-top: 10%;
  z-index: 1;
  text-align: center;

  font-size: 36px;
  font-weight: 500;
  @media (max-width: 600px) {
    padding-top: 50px;

    font-size: 2em;
  }
  @media (max-width: 500px) {
    padding-top: 25px;
    margin-top: 0;

    font-size: 2em;
  }
}

.circle-content {
  position:relative;
  z-index: 1;
  text-align: center;

  font-size: 20px;
  font-weight: 500;
  @media (max-width: 600px) {
    padding-right: 10%;
    padding-left: 10%;

    font-size: 3vw;
  }
}

.error-text {
  color: #e94d23;
}

ul.share-buttons{
  list-style: none;
  padding: 0;
}

ul.share-buttons li{
  display: inline;
}

ul.share-buttons .sr-only {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
