.header{

	color:white;
	text-align:center;	

	h1{
		margin: 20px 20px;
		margin-top:40px;
	}
	p{
		max-width: 728px;
		font-size: 18px;
		text-align:center;	
		margin:0 auto;
		margin-bottom: 40px;

	}
}

.logo-bar{
	background-color: $gray-light;
	min-height: 154px;
	text-align:center;
	img{
		text-align:center;
		max-width:200px;
		width: 100%;
	}
}

.logo-bar-recruit{
	background-color: $gray-light;
	min-height: 154px;
	text-align:center;
	img{
		text-align:center;
		max-width:150px;
		width: 100%;
		padding-top:30px;
		padding-bottom:30px;
	}
}

.feature-left{
	padding-top: 75px;
	padding-bottom: 75px;

	.img-section{
		padding-left:0;
		vertical-align:center;

		@media(max-width: $screen-md){
			.img-div{
				max-width: 500px;
			}
		}

	}
	.content-section{
		padding-left:30px;
		padding-right:50px;
		@media(min-width: 1200px){
		margin-top: 5%;
		.inner{
			max-width:600px;
		}
	}


	}
	.lm-button{
		font-size:14px;
		padding: 0 16px;
	}

}

.feature-right{
	padding-top: 75px;
	padding-bottom: 75px;

	.img-section{
		padding-right:0;
		vertical-align:center;
		overflow: hidden;
		@media(max-width: $screen-md){

			img{
				float: right;
				max-width: 500px;
			}
			.img-div{
				
			}
		}



	}
	.content-section{
		padding-right:30px;
		padding-left:50px;
		@media(min-width: 1200px){
		margin-top: 5%;
		.inner{
			max-width:600px;
		}
	}


	}
	.lm-button{
		font-size:14px;
		padding: 0 16px;
	}

}

.quote-bar{
	
	padding-top: 50px;
	padding-bottom: 50px;

	.quote{
		font-style: italic;
		font-size: 19px;
		font-weight: normal;
	}
	.author{
		font-size: 14px;
	}
	.school-logo{

	}
}

.colored-section{
	color:white;
	padding-top: 75px;
	padding-bottom: 75px;
}

.extra-features{
	padding-top: 75px;
	padding-bottom: 75px;
	.inner{
		max-width: 1200px;
	}

	@media(max-width: $screen-sm){
		text-align: center;
		.no-left{
			padding-left: 15px;
      margin-left: auto;
		}
	}
}

.featurel{
	margin-left: 0;
	padding-left: 0;
	margin-right: 0;
	padding-right: 0;
	@media (max-width: $screen-md){
    margin-left: 8.33333%;

	}
	@media (max-width: $screen-sm){
    margin-left: 15px;

	}
}


.positions-scroll{
	background-color: $brand-info;
	color: #ffffff;
	padding:5px;
	font-weight: 600;
}

.support-lang{
	i{
		font-size:70px;
		
	}
	padding-top:5px;
	padding-bottom:5px;
}

.support-lang-text{
	font-size:20px;
	font-weight:600;
	padding-top:10px;
	padding-bottom:10px;
}
