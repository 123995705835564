.newsletter {
  background-color: $brand-orange;
  padding: 32px 16px 60px;
  color: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .newsletter--text {
    flex: 1 1 100%;
    margin-bottom: 40px;

    h1 {
      font-size: 24px;
      line-height: 120%;
      margin-bottom: 24px;
    }

    h2 {
      font-size: 16px;
      line-height: 160%;
    }
  }

  .newsletter--form {
    flex: 1 1 100%;
  }

  .newsletter--label {
    font-size: 16px;
    line-height: 120%;
    display: block;
    margin-bottom: 8px;
  }

  .newsletter--email {
    width: 100%;
    height: 48px;
    background-color: #fff;
    border-radius: 2px;
    border: none;
    padding: 8px 16px;
    font-size: 20px;
    color: $input-color;
    margin-bottom: 32px;
  }

  .newsletter--submit {
    background-color: #fff;
    font-weight: bold;
    color: $brand-orange;
    border: none;
    font-size: 18px;
    line-height: 130%;
    padding: 10px 24px;
    border-radius: 2px;
    border: 2px solid #fff;

    &:hover {
      border-color: $brand-orange;
    }
  }

  #mc-embedded-subscribe {
    margin: 0;
  }

  .newsletter--success {
    display: none;
  }

  .newsletter--successHeading {
    font-size: 20px;
    font-weight: bold;
    display: block;
    margin-bottom: 16px;
  }

  .newsletter--successSubheading {
    font-size: 16px;
    display: block;
  }

  @media (min-width: 800px) {
    flex-wrap: nowrap;
    padding: 64px 96px 96px;

    .newsletter--text {
      flex: 0 0 50%;
      padding-right: 40px;

      h1 {
        font-size: 32px;
      }

      h2 {
        font-size: 20px;
      }
    }

    .newsletter--form {
      flex: 0 0 50%;
      padding-left: 40px;
    }

    .newsletter--email {
      height: 44px;
      font-size: 18px;
    }

    .newsletter--successHeading {
      font-size: 24px;
    }

    .newsletter--successSubheading {
      font-size: 18px;
    }
  }
}
